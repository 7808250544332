import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.$style.searchBar),
    "aria-haspopup": "dialog",
    "aria-label": _ctx.$t('search.triggerButton'),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.searchBoxContainer)
    }, [
      _createVNode(_component_Icon, {
        class: _normalizeClass(_ctx.$style.searchIcon),
        name: "search"
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.searchBox)
      }, [
        (_ctx.query)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.query), 1))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(_ctx.$style.placeholder)
            }, _toDisplayString(_ctx.$t('search.placeholder')), 3))
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.searchButton)
    }, _toDisplayString(_ctx.$t('search.searchButton')), 3)
  ], 10, _hoisted_1))
}