<template>
  <form
    :class="$style.searchBar"
    @submit="onSubmit"
  >
    <div
      :class="$style.searchBoxContainer"
      @focusin="suggestionsShown = true"
      @focusout="suggestionsShown = false"
      @keydown.up="onUp"
      @keydown.down="onDown"
      @keydown.left="onLeft"
      @keydown.right="onRight"
      @keydown.enter="onEnter"
      @keydown.escape="onEscape"
    >
      <Icon
        :class="$style.searchIcon"
        name="search"
      />

      <input
        ref="searchBox"
        v-model="inputQuery"
        type="text"
        :class="$style.searchBox"
        :placeholder="$t('search.placeholder')"
        autocomplete="off"

        role="combobox"
        aria-autocomplete="list"
        aria-haspopup="grid"
        :aria-controls="suggestionsId"
        :aria-expanded="suggestionsShown"
        :aria-activedescendant="selectedCell?.id"
        :aria-label="$t('search.inputLabel')"
      />

      <div
        v-if="suggestionsShown && groups.length > 0"
        :id="suggestionsId"
        :class="$style.suggestions"
        role="grid"
      >
        <template
          v-for="group in groups"
          :key="group.id"
        >
          <ul
            v-if="group.suggestions.length"
            :key="group.id"
            :class="$style.suggestionGroup"
            role="rowgroup"
            :aria-label="group.label"
          >
            <li
              :class="$style.suggestionGroupLabel"
              role="presentation"
            >
              {{ group.label }}
            </li>
            <li
              v-for="suggestion in group.suggestions"
              :key="suggestion.id"
              :class="$style.suggestion"
              role="row"
            >
              <Icon
                :class="[$style[group.iconName], $style.suggestionIcon]"
                :name="group.iconName"
              />
              <span
                :id="suggestion.select.id"
                :class="[
                  $style.suggestionText,
                  { [$style.selected]: suggestion.select.id === selectedCell?.id }
                ]"
                role="gridcell"
                @mousedown.prevent="() => triggerAction(suggestion.select)"
              >
                {{ suggestion.text }}
              </span>
              <span
                v-if="suggestion.delete"
                :id="suggestion.delete.id"
                :class="[
                  $style.suggestionDeleteButton,
                  { [$style.selected]: suggestion.delete.id === selectedCell?.id }
                ]"
                role="gridcell"
                :aria-label="$t('search.deleteButton', { query: suggestion.text })"
                @mousedown.prevent="() => triggerAction(suggestion.delete)"
              >
                <Icon
                  :class="$style.trashcan"
                  name="trashcan"
                />
              </span>
            </li>
          </ul>
        </template>
      </div>
    </div>

    <button
      v-show="inputQuery"
      type="button"
      :class="$style.clearButton"
      :aria-label="$t('search.clearButton')"
      @click="clearInput"
      @keydown.enter="clearInput"
    >
      <Icon
        name="dismiss"
        :class="$style.clearIcon"
      />
    </button>

    <button
      type="submit"
      :class="$style.searchButton"
    >
      {{ $t('search.searchButton') }}
    </button>
  </form>
</template>

<script lang="ts">
import { SearchAction, SearchGroup } from 'app/components/SearchBar.vue';
import { generateUUID } from 'lib/common/uuid';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    query: {
      type: String,
      required: true
    },
    groups: {
      type: Array as PropType<SearchGroup[]>,
      required: true
    }
  },
  emits: [
    'submit',
    'update:query'
  ],
  setup: (props, { emit }) => {
    const searchBox = ref<HTMLElement | null>(null);

    const suggestionsShown = ref<boolean>(false);
    const suggestionsId = generateUUID();

    const inputQuery = computed({
      get: () => props.query,
      set: (val: string) => emit('update:query', val)
    });

    const grid = ref<SearchAction[][]>([]);
    watch(() => {
      const suggestions = props.groups.flatMap((group) => group.suggestions);
      const cells = suggestions.map((suggestion) => {
        const actions = [suggestion.select];
        if (suggestion.delete) { actions.push(suggestion.delete); }

        return actions;
      });

      grid.value = cells;
    }, { immediate: true });

    const selectedIndexes = ref<[number, number]>([-1, 0]);
    const selectedCell = computed(() => {
      const [rowIndex, colIndex] = selectedIndexes.value;
      if (rowIndex === -1) { return undefined; }

      return grid.value[rowIndex][colIndex];
    });

    const resetIndexes = () => {
      selectedIndexes.value = [-1, 0];
    };

    watch(suggestionsShown, resetIndexes);

    watch(inputQuery, () => {
      resetIndexes();
      suggestionsShown.value = true;
    });

    const prevRowIndex = (rowIndex: number) => {
      const rowMin = 0;
      const rowMax = grid.value.length - 1;
      const rowPrev = rowIndex - 1;

      return rowPrev < rowMin ? rowMax : rowPrev;
    };

    const nextRowIndex = (rowIndex: number) => {
      const rowMin = 0;
      const rowMax = grid.value.length - 1;
      const rowNext = rowIndex + 1;

      return rowNext > rowMax ? rowMin : rowNext;
    };

    const firstColumnIndex = (rowIndex: number) => {
      return 0;
    };

    const lastColumnIndex = (rowIndex: number) => {
      const row = grid.value[rowIndex];

      return row.length - 1;
    };

    const boundColumnIndex = (rowIndex: number, colIndex: number) => {
      const row = grid.value[rowIndex];
      const colMin = 0;
      const colMax = row.length - 1;

      return Math.max(colMin, Math.min(colMax, colIndex));
    };

    const onUp = (event: KeyboardEvent) => {
      event.preventDefault();

      if (!suggestionsShown.value) { suggestionsShown.value = true; }

      const [rowIndex, colIndex] = selectedIndexes.value;

      // Go to the previous row, wrapping if at the top
      const newRowIndex = prevRowIndex(rowIndex);

      // Bound the column index based on the new row
      const newColIndex = boundColumnIndex(newRowIndex, colIndex);

      selectedIndexes.value = [newRowIndex, newColIndex];
    };

    const onDown = (event: KeyboardEvent) => {
      event.preventDefault();

      if (!suggestionsShown.value) { suggestionsShown.value = true; }

      const [rowIndex, colIndex] = selectedIndexes.value;

      // Go to the next row, wrapping if at the bottom
      const newRowIndex = nextRowIndex(rowIndex);

      // Bound the column index based on the new row
      const newColIndex = boundColumnIndex(newRowIndex, colIndex);

      selectedIndexes.value = [newRowIndex, newColIndex];
    };

    const onLeft = (event: KeyboardEvent) => {
      const [rowIndex, colIndex] = selectedIndexes.value;

      if (rowIndex === -1) { return; }
      event.preventDefault();

      // Go to the previous column, moving to the previous row if at the first column
      const colMin = 0;
      const colPrev = colIndex - 1;

      if (colPrev < colMin) {
        const newRowIndex = prevRowIndex(rowIndex);
        const newColIndex = lastColumnIndex(newRowIndex);

        selectedIndexes.value = [newRowIndex, newColIndex];
      } else {
        selectedIndexes.value = [rowIndex, colPrev];
      }
    };

    const onRight = (event: KeyboardEvent) => {
      const [rowIndex, colIndex] = selectedIndexes.value;

      if (rowIndex === -1) { return; }
      event.preventDefault();

      // Go to the next column, moving to the next row if at the last column
      const colMax = grid.value[rowIndex].length - 1;
      const colNext = colIndex + 1;

      if (colNext > colMax) {
        const newRowIndex = nextRowIndex(rowIndex);
        const newColIndex = firstColumnIndex(newRowIndex);

        selectedIndexes.value = [newRowIndex, newColIndex];
      } else {
        selectedIndexes.value = [rowIndex, colNext];
      }
    };

    const onEscape = (event: KeyboardEvent) => {
      event.preventDefault();

      if (selectedCell.value) {
        suggestionsShown.value = false;
      } else {
        inputQuery.value = '';
      }
    };

    const onSubmit = (event: Event) => {
      event.preventDefault();

      emit('submit');
    };

    const onEnter = async (event: KeyboardEvent) => {
      event.preventDefault();

      triggerAction(selectedCell.value);
    };

    const triggerAction = async (action?: SearchAction) => {
      let shouldClose = true;

      if (action) {
        shouldClose = action.onSelect();
        resetIndexes();
      } else {
        emit('submit');
      }

      if (shouldClose) {
        // Wait for watchers to trigger before closing
        await nextTick();
        suggestionsShown.value = false;
      }
    };

    const clearInput = (event: Event) => {
      event.preventDefault();

      inputQuery.value = '';
      searchBox.value?.focus();
    };

    return {
      inputQuery,
      suggestionsId,
      suggestionsShown,
      searchBox,
      selectedCell,

      clearInput,
      onUp,
      onDown,
      onLeft,
      onRight,
      onEscape,
      onEnter,
      onSubmit,
      triggerAction
    };
  }
});
</script>

<style module>
  /* #region SHARED STYLES */

  /* Bar */

  .search-bar {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'search clear submit';

    background-color: var(--c-dark-blue);
    border-radius: var(--form-border-radius);
    color: var(--c-white);
  }

  .search-box-container {
    grid-area: search;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'icon box';
    align-items: center;
  }

  .search-icon {
    grid-area: icon;

    fill: var(--c-white);
    height: 1.75rem;
    width: 1.75rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .search-box {
    grid-area: box;

    background: transparent;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    font-size: var(--fs-medium);
    font-weight: var(--fw-medium);
    line-height: 1.75rem;
    min-width: 3rem;
    overflow: hidden;
    padding: 0.25rem;
    margin: 0.25rem;
    text-align: start;
    white-space: nowrap;
  }

  .search-box::placeholder {
    color: var(--c-white);
    opacity: 0.75;
  }

  .clear-button {
    grid-area: clear;

    line-height: 0;
    padding: 0.5rem;
    margin: 0.25rem;
  }

  .clear-icon {
    fill: var(--c-white);
    height: 1rem;
    width: 1rem;
  }

  .search-button {
    grid-area: submit;

    background: var(--c-primary-red);
    border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    height: 100%;
    padding: 0.5em 1em;
    text-shadow: none;
    text-transform: uppercase;
  }

  .search-box:focus,
  .clear-button:focus,
  .search-button:focus {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 2px var(--c-dark-black), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  /* Suggestions box */

  .suggestions {
    --c-heading: var(--c-black);
    --c-icon: var(--c-black);
    --c-text: var(--c-primary-blue);
    --c-separator: var(--c-darkest-gray);
  }

  .suggestions {
    padding: 1rem;
  }

  .suggestion-group + .suggestion-group {
    border-top: 1px solid var(--c-separator);
    padding-top: 1rem;
  }

  .suggestion-group-label {
    color: var(--c-heading);
    font-weight: var(--fw-bold);
  }

  .suggestion {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1;
    margin: 0.75rem 0.25rem;
  }

  .suggestion > * {
    flex: none;
  }

  .suggestion-text {
    flex: 0 1 auto;
    color: var(--c-text);
    text-decoration: underline;
    text-align: start;
  }

  .suggestion svg {
    width: 1rem;
    height: 1rem;
  }

  .suggestion svg.expire-clock {
    stroke: var(--c-icon);
  }

  .suggestion svg.search,
  .suggestion svg.trashcan {
    fill: var(--c-icon);
  }

  .suggestion-text,
  .suggestion-delete-button {
    padding: 0.25rem;
    border-radius: var(--form-border-radius);
    cursor: pointer;
  }

  /* #endregion SHARED STYLES */

  /* #region SPECIFIC STYLES */

  .search-bar {
    position: relative;
    width: 60%;
  }

  .suggestions {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;

    width: 100%;
    padding: 1rem 2rem;
    background-color: var(--c-white);
    border-radius: .5rem;
    box-shadow: 0px 10px 15px 5px rgba(0,0,0,0.5);
    box-sizing: border-box;
    color: var(--c-dark-black);
  }

  .suggestion .selected {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 3px var(--c-white), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  /* #endregion SPECIFIC STYLES */
</style>
