import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, withKeys as _withKeys, vShow as _vShow } from "vue"

const _hoisted_1 = ["placeholder", "aria-controls", "aria-expanded", "aria-activedescendant", "aria-label"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["id", "onMousedown"]
const _hoisted_5 = ["id", "aria-label", "onMousedown"]
const _hoisted_6 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.searchBar),
    onSubmit: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.searchBoxContainer),
      onFocusin: _cache[1] || (_cache[1] = ($event: any) => (_ctx.suggestionsShown = true)),
      onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.suggestionsShown = false)),
      onKeydown: [
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onUp && _ctx.onUp(...args)), ["up"])),
        _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onDown && _ctx.onDown(...args)), ["down"])),
        _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onLeft && _ctx.onLeft(...args)), ["left"])),
        _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onRight && _ctx.onRight(...args)), ["right"])),
        _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
        _cache[8] || (_cache[8] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEscape && _ctx.onEscape(...args)), ["escape"]))
      ]
    }, [
      _createVNode(_component_Icon, {
        class: _normalizeClass(_ctx.$style.searchIcon),
        name: "search"
      }, null, 8, ["class"]),
      _withDirectives(_createElementVNode("input", {
        ref: "searchBox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputQuery) = $event)),
        type: "text",
        class: _normalizeClass(_ctx.$style.searchBox),
        placeholder: _ctx.$t('search.placeholder'),
        autocomplete: "off",
        role: "combobox",
        "aria-autocomplete": "list",
        "aria-haspopup": "grid",
        "aria-controls": _ctx.suggestionsId,
        "aria-expanded": _ctx.suggestionsShown,
        "aria-activedescendant": _ctx.selectedCell?.id,
        "aria-label": _ctx.$t('search.inputLabel')
      }, null, 10, _hoisted_1), [
        [_vModelText, _ctx.inputQuery]
      ]),
      (_ctx.suggestionsShown && _ctx.groups.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: _ctx.suggestionsId,
            class: _normalizeClass(_ctx.$style.suggestions),
            role: "grid"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: group.id
              }, [
                (group.suggestions.length)
                  ? (_openBlock(), _createElementBlock("ul", {
                      key: group.id,
                      class: _normalizeClass(_ctx.$style.suggestionGroup),
                      role: "rowgroup",
                      "aria-label": group.label
                    }, [
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$style.suggestionGroupLabel),
                        role: "presentation"
                      }, _toDisplayString(group.label), 3),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.suggestions, (suggestion) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: suggestion.id,
                          class: _normalizeClass(_ctx.$style.suggestion),
                          role: "row"
                        }, [
                          _createVNode(_component_Icon, {
                            class: _normalizeClass([_ctx.$style[group.iconName], _ctx.$style.suggestionIcon]),
                            name: group.iconName
                          }, null, 8, ["class", "name"]),
                          _createElementVNode("span", {
                            id: suggestion.select.id,
                            class: _normalizeClass([
                  _ctx.$style.suggestionText,
                  { [_ctx.$style.selected]: suggestion.select.id === _ctx.selectedCell?.id }
                ]),
                            role: "gridcell",
                            onMousedown: _withModifiers(() => _ctx.triggerAction(suggestion.select), ["prevent"])
                          }, _toDisplayString(suggestion.text), 43, _hoisted_4),
                          (suggestion.delete)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                id: suggestion.delete.id,
                                class: _normalizeClass([
                  _ctx.$style.suggestionDeleteButton,
                  { [_ctx.$style.selected]: suggestion.delete.id === _ctx.selectedCell?.id }
                ]),
                                role: "gridcell",
                                "aria-label": _ctx.$t('search.deleteButton', { query: suggestion.text }),
                                onMousedown: _withModifiers(() => _ctx.triggerAction(suggestion.delete), ["prevent"])
                              }, [
                                _createVNode(_component_Icon, {
                                  class: _normalizeClass(_ctx.$style.trashcan),
                                  name: "trashcan"
                                }, null, 8, ["class"])
                              ], 42, _hoisted_5))
                            : _createCommentVNode("", true)
                        ], 2))
                      }), 128))
                    ], 10, _hoisted_3))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 10, _hoisted_2))
        : _createCommentVNode("", true)
    ], 34),
    _withDirectives(_createElementVNode("button", {
      type: "button",
      class: _normalizeClass(_ctx.$style.clearButton),
      "aria-label": _ctx.$t('search.clearButton'),
      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args))),
      onKeydown: _cache[10] || (_cache[10] = _withKeys(
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args)), ["enter"]))
    }, [
      _createVNode(_component_Icon, {
        name: "dismiss",
        class: _normalizeClass(_ctx.$style.clearIcon)
      }, null, 8, ["class"])
    ], 42, _hoisted_6), [
      [_vShow, _ctx.inputQuery]
    ]),
    _createElementVNode("button", {
      type: "submit",
      class: _normalizeClass(_ctx.$style.searchButton)
    }, _toDisplayString(_ctx.$t('search.searchButton')), 3)
  ], 34))
}