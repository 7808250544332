<template>
  <dialog
    ref="dialog"
    :class="$style.dialog"
    :aria-label="$t('search.mobileHeading')"
    @keydown.esc.prevent="closeDialog"
  >
    <div class="notch"></div>
    <form
      :class="$style.searchBar"
      @submit.prevent="() => triggerAction()"
    >
      <button
        type="button"
        :class="$style.closeButton"
        :aria-label="$t('search.closeButton')"
        @click="$emit('close')"
      >
        <Icon name="chevron-left" />
      </button>
      <div :class="$style.searchBoxContainer">
        <input
          ref="searchBox"
          v-model="inputQuery"
          :class="$style.searchBox"
          type="text"
          :placeholder="$t('search.placeholder')"
          :aria-label="$t('search.placeholder')"
          @keydown.escape.prevent.stop="clearInput"
        />
      </div>
      <button
        v-if="inputQuery"
        type="button"
        :class="$style.clearButton"
        :aria-label="$t('search.clearButton')"
        @click="clearInput"
      >
        <Icon
          name="dismiss"
          :class="$style.clearIcon"
        />
      </button>
      <button
        type="submit"
        :class="$style.searchButton"
      >
        {{ $t('search.searchButton') }}
      </button>
    </form>

    <div :class="$style.suggestions">
      <template
        v-for="group in groups"
        :key="group.id"
      >
        <h2 :class="$style.suggestionGroupLabel">
          {{ group.label }}
        </h2>
        <ul
          :class="$style.suggestionGroup"
        >
          <li
            v-for="suggestion in group.suggestions"
            :key="suggestion.id"
            :class="$style.suggestion"
          >
            <Icon
              :class="[$style[group.iconName], $style.suggestionIcon]"
              :name="group.iconName"
            />
            <button
              :id="suggestion.select.id"
              :class="$style.suggestionText"
              @click="() => triggerAction(suggestion.select)"
            >
              {{ suggestion.text }}
            </button>
            <button
              v-if="suggestion.delete"
              :id="suggestion.delete.id"
              :class="$style.suggestionDeleteButton"
              :aria-label="$t('search.deleteButton', { query: suggestion.text })"
              @click="() => triggerAction(suggestion.delete)"
            >
              <Icon
                :class="$style.trashcan"
                name="trashcan"
              />
            </button>
          </li>
        </ul>
      </template>
    </div>
  </dialog>
</template>

<script lang='ts'>
import { SearchAction, SearchGroup } from 'app/components/SearchBar.vue';
import { useDialog } from 'app/functions/use-dialog';
import { computed, defineComponent, PropType, ref, SetupContext } from 'vue';

export default defineComponent({
  props: {
    query: {
      type: String,
      required: true
    },
    groups: {
      type: Array as PropType<SearchGroup[]>,
      required: true
    }
  },
  emits: [
    'close',
    'update:query',
    'submit'
  ],
  setup: (props, ctx) => {
    const searchBox = ref<HTMLInputElement | null>(null);

    const inputQuery = computed({
      get: () => props.query,
      set: (val) => ctx.emit('update:query', val)
    });

    const clearInput = () => {
      inputQuery.value = '';
      if (searchBox.value) {
        searchBox.value.focus();
      }
    };

    const triggerAction = async (action?: SearchAction) => {
      let shouldClose = true;

      if (action) {
        shouldClose = action.onSelect();
      } else {
        ctx.emit('submit');
      }

      if (shouldClose) {
        ctx.emit('close');
      }
    };

    const {
      dialog,
      closeDialog
    } = useDialog(ctx as SetupContext);

    return {
      clearInput,
      closeDialog,
      dialog,
      inputQuery,
      searchBox,
      triggerAction
    };
  }
});
</script>

<style module>
  /* #region SHARED STYLES */

  /* Bar */

  .search-bar {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'search clear submit';

    background-color: var(--c-dark-blue);
    border-radius: var(--form-border-radius);
    color: var(--c-white);
  }

  .search-box-container {
    grid-area: search;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'icon box';
    align-items: center;
  }

  .search-icon {
    grid-area: icon;

    fill: var(--c-white);
    height: 1.75rem;
    width: 1.75rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .search-box {
    grid-area: box;

    background: transparent;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    font-size: var(--fs-medium);
    font-weight: var(--fw-medium);
    line-height: 1.75rem;
    min-width: 3rem;
    overflow: hidden;
    padding: 0.25rem;
    margin: 0.25rem;
    text-align: start;
    white-space: nowrap;
  }

  .search-box::placeholder {
    color: var(--c-white);
    opacity: 0.75;
  }

  .clear-button {
    grid-area: clear;

    line-height: 0;
    padding: 0.5rem;
    margin: 0.25rem;
  }

  .clear-icon {
    fill: var(--c-white);
    height: 1rem;
    width: 1rem;
  }

  .search-button {
    grid-area: submit;

    background: var(--c-primary-red);
    border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
    border: none;
    box-sizing: border-box;
    color: var(--c-white);
    height: 100%;
    padding: 0.5em 1em;
    text-shadow: none;
    text-transform: uppercase;
  }

  .search-box:focus,
  .clear-button:focus,
  .search-button:focus {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 2px var(--c-dark-black), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  /* Suggestions box */

  .suggestions {
    --c-heading: var(--c-black);
    --c-icon: var(--c-black);
    --c-text: var(--c-primary-blue);
    --c-separator: var(--c-darkest-gray);
  }

  .suggestions {
    padding: 1rem;
  }

  .suggestion-group + .suggestion-group {
    border-top: 1px solid var(--c-separator);
    padding-top: 1rem;
  }

  .suggestion-group-label {
    color: var(--c-heading);
    font-weight: var(--fw-bold);
  }

  .suggestion {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1;
    margin: 0.75rem 0.25rem;
  }

  .suggestion > * {
    flex: none;
  }

  .suggestion-text {
    flex: 0 1 auto;
    color: var(--c-text);
    text-decoration: underline;
    text-align: start;
  }

  .suggestion svg {
    width: 1rem;
    height: 1rem;
  }

  .suggestion svg.expire-clock {
    stroke: var(--c-icon);
  }

  .suggestion svg.search,
  .suggestion svg.trashcan {
    fill: var(--c-icon);
  }

  .suggestion-text,
  .suggestion-delete-button {
    padding: 0.25rem;
    border-radius: var(--form-border-radius);
    cursor: pointer;
  }

  /* #endregion SHARED STYLES */

  /* #region SPECIFIC STYLES */

  .dialog {
    background: var(--c-darkest-blue);
    padding: 0.5rem;
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    box-sizing: border-box;
  }

  .search-bar {
    grid-template-columns: auto 1fr auto auto;
    grid-template-areas: 'close search clear submit';
  }

  .close-button {
    grid-area: close;

    line-height: 0;
    padding: 0.25rem;
  }

  .close-button:focus,
  .suggestion-text:focus,
  .suggestion-delete-button:focus {
    outline: 3px solid transparent;
    outline-offset: 3px;
    box-shadow: inset 0 0 0 2px var(--c-dark-black), 0 0 0 3px var(--c-focus);
    border-radius: 8px;
  }

  .close-button svg {
    fill: var(--c-white);
    width: 2rem;
    height: 2rem;
  }

  .suggestions {
    --c-heading: var(--c-white);
    --c-icon: var(--c-white);
    --c-text: var(--c-white);
    --c-separator: var(--c-white);
  }

  /* #endregion SPECIFIC STYLES */

</style>
