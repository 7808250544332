<template>
  <SearchBarMobile
    v-if="mobile"
    v-model:query="query"
    :groups="suggestionGroups"
    @submit="submit"
  />
  <SearchBarDesktop
    v-else
    v-model:query="query"
    :groups="suggestionGroups"
    @submit="submit"
  />
</template>

<script lang='ts'>
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import SearchBarDesktop from 'app/components/SearchBarDesktop.vue';
import SearchBarMobile from 'app/components/SearchBarMobile.vue';
import { useI18n } from 'app/functions/use-i18n';
import { usePatron } from 'app/functions/use-patron';
import { RouteName } from 'app/router/constants';
import router from 'app/router/router';
import { debounce, generateUUID } from 'lib/common';
import { computed, defineComponent, ref, watch } from 'vue';

export type SearchGroup = {
  id: string;
  label: string;
  iconName: string;
  suggestions: SearchSuggestion[];
};

type SearchSuggestion = {
  id: string;
  text: string;
  select: SearchAction;
  delete?: SearchAction;
};

export type SearchAction = {
  id: string;
  onSelect: () => boolean;
};


export default defineComponent({
  components: {
    SearchBarDesktop,
    SearchBarMobile
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  setup: () => {
    const { t } = useI18n();

    const baseId = generateUUID();

    const query = ref<string>('');

    const submit = () => {
      const trimmedQuery = query.value.trim();
      if (trimmedQuery.length) {
        APP.patron.searchHistory.add(trimmedQuery);

        const newRoute = {
          name: RouteName.Search,
          query: {
            query: C.encode(trimmedQuery)
          }
        };
        router.push(newRoute);
      }
    };

    // Selection actions. Return true to close any open lists or dialogs.
    const selectOption = (text: string) => {
      query.value = text;
      submit();

      return true;
    };
    const removeFromHistory = (text: string) => {
      APP.patron.searchHistory.remove(text);

      return false;
    };

    const autocompleteSuggestions = ref<string[]>([]);
    watch(query, debounce(async (text) => {
      if (!text || text.length < 3) {
        autocompleteSuggestions.value = [];

        return;
      }

      try {
        const response = await APP.services.autocomplete.getSuggestions({ query: text, maxSize: 5 });
        autocompleteSuggestions.value = response?.items.map((i) => i.text) || [];
      } catch {
        autocompleteSuggestions.value = [];
      }
    }, 500), { immediate: true });

    const autocompleteGroup = computed<SearchGroup>(() => {
      const groupId = `${baseId}-autocomplete`;

      const suggestions = autocompleteSuggestions.value.map((text, index) => {
        const suggestionId = `${groupId}-${index}`;

        return {
          id: suggestionId,
          text,
          select: {
            id: `${suggestionId}-text`,
            onSelect: selectOption.bind(null, text)
          }
        };
      });

      return {
        id: groupId,
        label: t('search.suggestions.autocomplete'),
        iconName: 'search',
        suggestions: suggestions
      };
    });

    const { searchHistory } = usePatron();
    const recentSearches = computed<string[]>(() => searchHistory.value.slice(0, 5));

    const recentGroup = computed<SearchGroup>(() => {
      const groupId = `${baseId}-recent`;

      const suggestions = recentSearches.value.map((text, index) => {
        const suggestionId = `${groupId}-${index}`;

        return {
          id: suggestionId,
          text,
          select: {
            id: `${suggestionId}-text`,
            onSelect: selectOption.bind(null, text)
          },
          delete: {
            id: `${suggestionId}-delete`,
            onSelect: removeFromHistory.bind(null, text)
          }
        };
      });

      return {
        id: groupId,
        label: t('search.suggestions.recent'),
        iconName: 'expire-clock',
        suggestions: suggestions
      };
    });

    const suggestionGroups = computed<SearchGroup[]>(() => [
      autocompleteGroup.value,
      recentGroup.value
    ].filter((g) => g.suggestions.length));

    return {
      query,
      suggestionGroups,
      submit
    };
  }
});
</script>

<style module>

</style>
