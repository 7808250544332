import { SearchDefinition, SearchRequest, SearchSortOption, SearchType } from 'app/base/search';
import { watchLoadingState, watchResultCount } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { mergeFilterSubjects, useSearch } from 'app/functions/use-search';
import { SubjectFilters, getQueryParametersFromSubjectFilters } from 'app/functions/use-subject-filters';
import { Ref, computed } from 'vue';
import { RouteLocationNormalizedLoaded, RouteLocationRaw, useRoute, useRouter } from 'vue-router';

export type ListTabId = 'title' | 'set';

export type ListPageModifications = {
  currentTab?: ListTabId;
  appliedFilters?: SubjectFilters;
  currentSort?: SearchSortOption;
  titlePage?: number;
  seriesPage?: number;
};

export type ListPageConfig = {
  libraryKey: string;
  definition: SearchDefinition;
  tabOptions: ListTabId[];
  sortOptions: SearchSortOption[];
  modifications: ListPageModifications;
};

type ListPageQueryParams = {
  tab?: ListTabId;
  sort?: SearchSortOption;
  filters?: SubjectFilters;
  tPage?: number;
  sPage?: number;
  page?: number;
};


export function useListPage(
  config: Ref<ListPageConfig>
) {
  const { t } = useI18n();

  const sortOptions = computed(() => config.value.sortOptions.map((s) => ({
    label: t(`list.sort.${s}`),
    id: s
  })));

  const currentSortOption = computed(() => {
    return sortOptions.value.find((o) => o.id === config.value.modifications.currentSort) || sortOptions.value[0];
  });

  const titleRequest = computed(() => {
    if (!config.value.tabOptions.includes('title')) { return undefined; }

    return {
      libraryKey: config.value.libraryKey,
      searchType: SearchType.Title,
      definition: config.value.definition,
      modifications: {
        sort: currentSortOption.value.id,
        subjects: config.value.modifications.appliedFilters,
        page: config.value.modifications.titlePage
      }
    } as SearchRequest<SearchType.Title>;
  });

  const seriesRequest = computed(() => {
    if (!config.value.tabOptions.includes('set')) { return undefined; }

    return {
      libraryKey: config.value.libraryKey,
      searchType: SearchType.Series,
      definition: config.value.definition,
      modifications: {
        sort: currentSortOption.value.id,
        subjects: config.value.modifications.appliedFilters,
        page: config.value.modifications.seriesPage
      }
    } as SearchRequest<SearchType.Series>;
  });

  const { state: titleState } = useSearch(titleRequest);
  const { state: seriesState } = useSearch(seriesRequest);

  const allFilters = computed(() => {
    const titleSubjects = titleState.value?.state === 'success'
      ? titleState.value.response.subjects
      : undefined;

    const seriesSubjects = seriesState.value?.state === 'success'
      ? seriesState.value.response.subjects
      : undefined;

    return mergeFilterSubjects(titleSubjects, seriesSubjects);
  });

  const resultCount = computed(() => {
    const titleCount = titleState.value?.state === 'success'
      ? titleState.value.response.list.totalItems
      : 0;

    const seriesCount = seriesState.value?.state === 'success'
      ? seriesState.value.response.list.totalItems
      : 0;

    const total = titleCount + seriesCount;

    return t('list.numberResults', { NUMBER: total.toString() });
  });

  const router = useRouter();
  const currentRoute = useRoute();
  const updatePath = (params: ListPageQueryParams) => {
    router.replace(newPath(currentRoute, params, breakpoint.value));
  };

  const updatePage = (page: number) => {
    const tabPage = config.value.tabOptions.length === 1 ? 'page'
      : config.value.modifications.currentTab === 'set' ? 'sPage'
      : 'tPage';

    updatePath({ [tabPage]: page });
  };

  const updateSort = (sort: any) => {
    const pageParams: { tPage?: number; sPage?: number; page?: number } = {};
    if (config.value.tabOptions.length > 1) {
      pageParams.tPage = 1;
      pageParams.sPage = 1;
    } else {
      pageParams.page = 1;
    }

    updatePath({ sort: sort.id, ...pageParams });
  };


  // Chatterbox

  watchLoadingState(computed(() => {
    return titleState.value?.state === 'loading' || seriesState.value?.state === 'loading';
  }), true);

  watchResultCount(resultCount);


  // Breakpoint

  const breakpoint = computed(() => {
    if (titleState.value?.state === 'success' && titleState.value.response.breakpoint) {
      return titleState.value.response.breakpoint;
    }

    if (seriesState.value?.state === 'success' && seriesState.value.response.breakpoint) {
      return seriesState.value.response.breakpoint;
    }

    return undefined;
  });


  return {
    allFilters,
    currentSortOption,
    resultCount,
    seriesState,
    sortOptions,
    titleState,
    updatePage,
    updateSort,
    updatePath
  };
}


 function newPath(
  route: RouteLocationNormalizedLoaded,
  params: ListPageQueryParams,
  breakpoint: string | undefined
): RouteLocationRaw {
  return {
    name: route.name!,
    params: route.params,
    query: {
      ...route.query,
      ...getQueryParametersFromSubjectFilters(params.filters),
      sort: params.sort || route.query.sort,
      tPage: params.tPage || route.query.tPage,
      sPage: params.sPage || route.query.sPage,
      page: params.page || route.query.page,
      breakpoint: breakpoint || route.query.breakpoint
    },
    hash: params.tab ? `#${params.tab}` : route.hash
  };
}
