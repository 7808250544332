import { C, Dictionary } from 'app/base/common';
import { Constants } from 'app/base/constants';
import env from 'app/base/env';
import { LexisMetadata } from '../models/title';
import { Service } from './services/service';

export class Thunder extends Service {
  private readonly BATCH_SIZE = 50;
  private readonly queryParameters: Dictionary<string> = {
    ['x-client-id']: 'elrond'
  };


  constructor() {
    super('THUNDER', (path: string) => {
      return { url: C.parameterizeURL(`${env.THUNDER_URI}/v2/${path}`, this.queryParameters) };
    });
  }


  public getLibraryByKey(libraryKey: string): Promise<ThunderLibrary | null> {
    const route = `libraries/${libraryKey}`;

    return this.fetchAsync<ThunderLibrary>(route);
  }


  public async getLibraryByWebsiteId(websiteId: number): Promise<ThunderLibrary | null> {
    const route = C.parameterizeURL('libraries', { websiteId: websiteId });

    const libraryList = await this.fetchAsync<ThunderPagedResponse<ThunderLibrary>>(route);

    if (libraryList && libraryList.items && libraryList.items.length > 0) {
      return libraryList.items[0];
    }

    return null;
  }


  public getTitle(libraryKey: string, titleId: string): Promise<ThunderMediaResponse | null> {
    const route = `libraries/${libraryKey}/media/${titleId}`;

    return this.fetchAsync<ThunderMediaResponse>(route);
  }


  public async getTitles(libraryKey: string, titleIds: string[]): Promise<ThunderItemsResponse<ThunderMediaResponse> | null> {
    const batches = C.chunk(titleIds, this.BATCH_SIZE);
    const output: ThunderItemsResponse<ThunderMediaResponse> = {items: []};
    const batchProms = batches.map(async (batch) => {
      const batchRoute = `libraries/${libraryKey}/media/bulk?titleIds=${batch.join(',')}`;

      return this.fetchAsync<ThunderItemsResponse<ThunderMediaResponse>>(batchRoute);
    });

    for (const item of await Promise.all(batchProms)) {
      output.items = output.items.concat(item.items);
    }

    return output;

  }


  public getSeries(libraryKey: string, seriesId: number): Promise<ThunderSeriesResponse | null> {
    const route = `libraries/${libraryKey}/series/${seriesId}`;

    return this.fetchAsync<ThunderSeriesResponse>(route);
  }

  public async getBulkSeries(libraryKey: string, seriesIds: number[]): Promise<ThunderItemsResponse<ThunderSeriesResponse> | null> {
    const batches = C.chunk(seriesIds, this.BATCH_SIZE);
    const output = { items: [] as ThunderSeriesResponse[] };
    const batchProms = batches.map(async (batch) => {
      return this.fetchAsync<ThunderSeriesResponse[]>({
        url: `libraries/${libraryKey}/series/bulk`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          seriesIds: batch
        })
      });
    });

    for (const item of await Promise.all(batchProms)) {
      if (item) {
        output.items = [...output.items, ...item];
      }
    }

    return output;
  }


  public getPages(
    libraryKey: string,
    includeCollectionDefinitions = false
  ): Promise<ThunderPagedResponse<ThunderRoom> | null> {
    const route = C.parameterizeURL(
      `libraries/${libraryKey}/pages`,
      { useNew: true, includeCollectionDefinitions: includeCollectionDefinitions });

    return this.fetchAsync<ThunderPagedResponse<ThunderRoom>>(route);
  }


  public async getHomePage(libraryKey: string): Promise<ThunderRoom | null> {
    const rooms = await this.getPages(libraryKey, true);

    if (rooms && rooms.items && rooms.items.length > 0) {
      return rooms.items[0];
    }

    return null;
  }


  public getCollectionDefinition(
    libraryKey: string,
    collectionId: string
  ): Promise<ThunderCollectionDefinition | null> {
    const route = `libraries/${libraryKey}/collections/${collectionId}/definition`;

    return this.fetchAsync<ThunderCollectionDefinition>(route);
  }


  public search(
    libraryKey: string,
    searchOptions: string | ThunderMediaParams
  ): Promise<ThunderSearchResponse<ThunderMediaResponse> | null> {
    const paramString = C.isString(searchOptions) ? searchOptions : C.parameterizeURL('', searchOptions);

    const route = `libraries/${libraryKey}/media${paramString}`;

    return this.fetchAsync<ThunderSearchResponse<ThunderMediaResponse>>(route);
  }


  // For backwards compatibility with older list setup. Ideally this should be removed.
  public searchWithFormats(
    libraryKey: string,
    searchOptions: string | ThunderMediaParams
  ): Promise<ThunderSearchResponse<ThunderMediaResponse>> {
    const paramString = C.isString(searchOptions) ? searchOptions : C.parameterizeURL('', searchOptions);
    const withFormats = C.parameterizeURL(paramString, { format: Constants.SUPPORTED_FORMATS.join(',') });

    // pretending this always has a result because all the calling code does
    const result = this.search(libraryKey, withFormats) as Promise<ThunderSearchResponse<ThunderMediaResponse>>;

    return result;
  }


  public seriesSearch(
    libraryKey: string,
    searchOptions: string | ThunderSeriesParams
  ): Promise<ThunderSearchResponse<ThunderSeriesResponse> | null> {
    const paramString = C.isString(searchOptions) ? searchOptions : C.parameterizeURL('', searchOptions);

    const route = `libraries/${libraryKey}/series${paramString}`;

    return this.fetchAsync<ThunderSearchResponse<ThunderSeriesResponse>>(route);
  }


  public getRecommendedTitles(
    libraryKey: string,
    titleId: string,
    numResults: number
  ): Promise<ThunderItemsResponse<ThunderMediaResponse> | null> {
    const route = C.parameterizeURL(
      `libraries/${libraryKey}/media/${titleId}/recommended`,
      { numResults: numResults }
    );

    return this.fetchAsync<ThunderItemsResponse<ThunderMediaResponse>>(route);
  }


  /**
   * @desc Fetches meta data for a title, without the context of a library
   * @param titleId The unique title ID to search for
   */
  public getGenericTitle(titleId: string): Promise<ThunderMediaResponse | null> {
    return this.fetchAsync<ThunderMediaResponse>(`media/${titleId}`);
  }


  public async getSubjects(libraryKey: string) {
    const route = C.parameterizeURL(`libraries/${libraryKey}/media`, { format: Constants.SUPPORTED_FORMATS.join(',') });

    const response = await this.fetchAsync<ThunderSearchResponse<ThunderMediaResponse>>(route);

    return response?.facets.subjects.items;
  }
}

export interface ThunderItemsResponse<T> {
  items: T[];
}

export interface ThunderPagedResponse<T> extends ThunderItemsResponse<T> {
  totalItems: number;
  totalItemsText: string;
  links: ThunderPagedLinks;
}

export interface ThunderSearchResponse<T> extends ThunderPagedResponse<T> {
  queryKeys: ThunderQueryKeys;
  sortOptions: ThunderSortOption[];
  facets: ThunderFacets;
  breakpoint?: string;
}

interface ThunderSortOption extends IdNamePair {
  isApplied: boolean;
  seed?: number;
}

export interface ThunderQueryKeys {
  publisher?: IdNamePair;
  creator?: IdNamePair;
  imprint?: IdNamePair;
  subjects?: IdNamePair[];
}

export interface ThunderMediaResponse {
  type: IdNamePair;
  coverColor?: [null, number, number];
  covers: ThunderCovers;
  coverURLForDPI?: string;
  id: string;
  firstCreatorName: string;
  firstCreatorId: number;
  title: string;
  sortTitle: string;
  starRating?: number;
  starRatingCount?: number;
  frequency: IdNamePair;
  pages?: number;
  parentMagazineTitleId: string;
  edition: string;
  reserveId: string;
  subjects: IdNamePair[];
  bisacCodes: string[];
  formats: ThunderFormat[];
  levels: IdNameValue[];
  creators: ThunderCreator[];
  languages: IdNamePair[];
  imprint: IdNamePair;
  isBundledChild: boolean;
  awards: { id: number; description: string; source: string }[];
  isAvailable: boolean;
  isPreReleaseTitle: boolean;
  estimatedReleaseDate?: string;
  publisher: IdNamePair;
  publisherAccount: IdNamePair;
  subtitle: string;
  series: string;
  slug?: string;
  tableOfContents?: string;
  description: string;
  availableCopies?: number;
  ownedCopies?: number;
  holdsCount?: number;
  holdsRatio?: number;
  estimatedWaitDays?: number;
  isFastlane: boolean;
  availabilityType: 'normal' | 'always' | 'limited' | 'unknown';
  isRecommendableToLibrary: boolean;
  isOwned: boolean;
  isHoldable: boolean;
  publishDate?: string;
  publishDateText?: string; // Sometimes Thunder returns this but not publish date.
  lexisMetadata?: LexisMetadata;
  bundledContentTitleIds: string[];
  bundledContentChildrenTitleIds?: string[];
  bundledContentParentTitleId?: string;
  detailedSeries: {
    rank: number;
    seriesId: number;
    readingOrder: string;
    seriesName: string;
  };
}

export interface ThunderSeriesResponse {
  id: number;
  name: string;
  items: ThunderSeriesItem[];
  lexisSeriesData?: ThunderLexisSeriesData;
}

export interface ThunderFormat extends IdNamePair {
  identifiers: ThunderIdentifier[];
  fileSize?: number;
  onSaleDateUtc?: string;
  partCount?: number;
  duration: string;
  hasAudioSynchronizedText: boolean;
  isBundleParent: boolean;
  isbn: string;
  bundledContent: { titleId: string }[];
}

interface ThunderCreator {
  id: number;
  name: string;
  role: string;
}

export interface ThunderIdentifier {
  type: string;
  value: string;
}

export interface ThunderCovers {
  cover150Wide: ThunderCover;
  cover300Wide: ThunderCover;
  cover510Wide: ThunderCover;
}

export interface ThunderCover {
  href: string;
  height?: number;
  width?: number;
  primaryColor: ThunderColor;
}

export interface ThunderFacets {
  mediaTypes: ThunderFacet;
  languages: ThunderFacet;
  subjects: ThunderFacet;
  formats: ThunderFacet;
  practiceArea: ThunderFacet;
  jurisdiction: ThunderFacet;
  classification: ThunderFacet;
  bisacCodes: ThunderFacet;
}

interface ThunderFacet {
  name: string;
  items: ThunderFacetItem[];
}

export interface ThunderFacetItem {
  id: string;
  name: string;
  isApplied: boolean;
  totalItems?: number;
}

interface ThunderSeriesItem {
  rank: number;
  readingOrder: string;
  title: string;
  id: string;
}

interface ThunderLexisSeriesData {
  earliestEstimatedReleaseDate: string;
  subjects: IdNamePair[];
  creators: ThunderCreator[];
  languages: IdNamePair[];
  publishers: IdNamePair[];
  formatClassifications: IdNamePair[];
  formats: IdNamePair & { mediaType: string }[];
  firstItem: ThunderSeriesFirstTitle;
}

interface ThunderSeriesFirstTitle {
  titleId: number;
  imageList: ThunderSeriesCover[];
  tableOfContents: string;
  fullDescription: string;
  shortDescription: string;
  edition: string;
  subtitle: string;
  title: string;
}

export interface ThunderSeriesCover {
  type: number;
  width: number;
  height: number;
  primaryColor: string;
  url: string;
}

interface ThunderPagedLinks {
  self: ThunderPagedLink;
  first: ThunderPagedLink;
  last: ThunderPagedLink;
  next: ThunderPagedLink;
}

interface ThunderPagedLink {
  page: number;
  pageText: string;
}

export interface ThunderRoom {
  id: string;
  name: string;
  description: string;
  themeName: string;
  urlPath: string;
  isDefault: string;
  isEnabled: string;
  isSticky: string;
  isFeatured: string;
  rank?: number;
  reportingOrigin: string;
  views: ThunderView[];
}

export interface ThunderView {
  collections: ThunderCollectionDetails[];
  collectionDefinitions: ThunderCollectionDefinition[];
  isDefault: boolean;
  name: string;
}

interface ThunderCollectionDetails {
  order: number;
  type: 'generated' | 'curated';
  name: string;
  id: string;
}

export type ThunderCollectionDefinition = ThunderGeneratedCollectionDefinition | ThunderCuratedCollectionDefinition;


export type ThunderGeneratedCollectionDefinition = BaseCollectionDefinition & { generatedCollectionDetails: GeneratedCollectionDetails };


export type ThunderCuratedCollectionDefinition = BaseCollectionDefinition & { curatedCollectionDetails: CuratedCollectionDetails };


type BaseCollectionDefinition = {
  showOnlyAvailable?: boolean;
  showOnlyLuckyDayAvailable?: boolean;
  sortByAvailability?: boolean;
  availabilityOption?: ThunderAvailabilityOption;
  sortBy: ThunderSortType;
  description: string;
  name: string;
  itemType: 'Title' | 'Series';
  id: string;
  lastModified: string;
};


type GeneratedCollectionDetails = {
  maxItems?: number;
  maturityLevels?: string[];
  subjects?: string[];
  publisherEntityId?: number;
  mediaTypes?: string[];
  language?: string;
};

type CuratedCollectionDetails = {
  curatedCollectionId: number;
  isOrdered: boolean;
};

export interface ThunderLibrary {
  websiteId: number;
  preferredKey: string;
  name: string;
  type: ThunderLibraryType;
  isLexisNexis: boolean;
  hasAdvantageAccounts: boolean;
  settings: ThunderLibrarySettings;
  fulfillmentId: string;
  status: ThunderLibraryStatus;
  accounts: number[];
}

type ThunderLibraryType = 'DLR' | 'SDL' | 'CDL' | 'Corporate';

export type ThunderLibraryStatus = 'Live' | 'Preview' | 'InDevelopment' | 'Merged' | 'Terminated';

interface ThunderLibrarySettings {
  primaryColor: ThunderColor;
  secondaryColor: ThunderColor;
  logo140X60: Link;
  disableNotes: boolean;
}

export interface ThunderColor {
  hex: string;
  rgb: Rgb;
}

interface Rgb {
  red: number;
  green: number;
  blue: number;
}

interface Link {
  href: string;
}

export interface IdNamePair {
  id: string;
  name: string;
}

interface IdNameValue extends IdNamePair {
  value: string;
}

export type ThunderAvailabilityOption =
  'available-first' |
  'available-luckyday' |
  'available-only';

export type ThunderSortType =
  'default' |
  'relevance' |
  'newlyadded' |
  'mostpopular' |
  'mostpopular-site' |
  'lowcirculating' |
  'releasedate' |
  'author' |
  'title' |
  'listorder' |
  'random' |
  'seriesname' |
  'publishdate';

type ThunderShowOption =
  'owned' |
  'unowned' |
  'all' |
  'recommendable';

export type ThunderMediaParams = {
  query?: string;
  mediaTypes?: string[];
  title?: string;
  series?: string;
  publisherId?: string;
  publisherEntityId?: string;
  imprintId?: string;
  identifier?: string;
  creator?: string;
  creatorId?: string;
  addedDate?: string;
  availableFirst?: boolean;
  showOnlyAvailable?: boolean;
  excludeNormallyAvailable?: boolean;
  showOnlyLuckyDayAvailable?: boolean;
  showOnlyPrerelease?: boolean;
  hasAudioSynchronizedText?: boolean;
  format?: string[];
  subject?: string[];
  bisacCode?: string[];
  excludedBisacCodes?: string[];
  maturityLevel?: string[];
  language?: string[];
  atosLevel?: string[];
  lexileScore?: string[];
  interestLevel?: string[];
  gradeLevel?: string[];
  awards?: string[];
  sortBy?: ThunderSortType[];
  subjectOrBisac?: string[];
  lexisNexisPracticeArea?: string[];
  lexisNexisJurisdiction?: string[];
  lexisNexisContentType?: string[];
  show?: ThunderShowOption;
  overdriveFormats?: boolean;
  page?: number;
  perPage?: number;
  includeFacets?: boolean;
  includeNoFormatTitles?: boolean;
  filterCpcTitles?: boolean;
  collectionId?: string;
  titleIds?: string;
  maxItems?: number;
  breakpoint?: string;
  contentMask?: number;
  seed?: number;
  includedFacets?: string[];
  searchBehavior?: string;
  useDefaultQuotable?: boolean;
};

export type ThunderSeriesParams = {
  query?: string;
  addedDate?: string;
  seriesName?: string;
  mediaType?: string[];
  format?: string[];
  subject?: string[];
  language?: string[];
  includedFacets?: string[];
  lexisNexisPracticeArea?: string[];
  lexisNexisJurisdiction?: string[];
  lexisNexisContentType?: string[];
  sortBy?: ThunderSortType;
  page?: number;
  perPage?: number;
  collectionId?: string;
  includeFacets?: boolean;
  imprintId?: string;
  publisherId?: string;
  publisherEntityId?: string;
  creatorId?: string;
  explain?: boolean;
  useDefaultQuotable?: boolean;
};
