import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { BuildEnvironment } from 'app/base/env';
import { CollationUpdate } from 'app/base/event-map';
import { AnalyticsAnnotationActivity, MediaType } from 'app/base/interfaces';
import { RemoteConfig } from 'app/base/remote-config';
import { ShellInfo } from 'app/base/shell/shell';
import { Analytics } from 'app/base/tracking/analytics';
import { ElrondGTMDataLayer, GTMHelper } from 'app/base/tracking/gtm-helper';
import { FirebaseConfig, ODFirebase } from 'app/base/tracking/od-firebase';
import Events from 'app/events/events';
import i18n from 'app/i18n/i18n';
import { AnalyticsEventName, AnalyticsEventParams } from 'app/keys/analytics-keys';
import { Library } from 'app/models/library';
import { Loan } from 'app/models/loan';
import { DataEvent } from 'lib/gala/src/events';
import { RouteLocationNormalized, stringifyQuery } from 'vue-router';

export type AppErrorContext = {
  description: string;
};

export type TitleOpenContext = {
  title_slug: string;
  title_name: string | null;
  reserveID: string | null;
  format: MediaType | null;
};

const BANK_KEY = 'tracking';


// Dependent on APP.server, APP.services to be set up. Should come before bank start to listen for startup.
export class Tracking {

  public readonly bankStart: Promise<boolean | undefined>;

  private readonly userId: string;

  private readonly config: FirebaseConfig;

  private isEnabled: boolean;

  private firebase: ODFirebase | undefined;

  private analytics: Analytics | undefined;

  private remoteConfig: RemoteConfig | undefined;

  private libraryId: string | undefined;

  private dataLayer: ElrondGTMDataLayer;


  constructor(env: BuildEnvironment) {
    this.isEnabled = false;

    this.userId = C.generateUUID();
    this.config = Tracking.readFirebaseConfig(env);

    this.dataLayer = {
      accountId: '',
      platform: 'Web',
      environment: env.ELROND_ENV,
      library: new Library(),
      ga: {
        property: '',
        view: ''
      },
      session: {
        checkedOut: 0
      }
    };

    this.registerGlobalEvents();

    if (APP.bank) {
      this.bankStart = this.initializeBank();
    } else {
      this.bankStart = new Promise((resolve) => {
        Events.on('bank:start', () => {
          resolve(this.initializeBank());
        });
      });
    }

    if (window.clarity) {
      window.clarity('consent', false);
      window.clarity('stop');
    }
  }


  public async enable() {
    // Wait for the bank to initialize so that multiple things aren't trying to write to it
    await this.bankStart;

    Tracking.setBankedValue(true);

    // Prevent issues initializing the same Firebase connection multiple times
    if (this.isEnabled) {
      console.log('[TRACKING] Tracking already enabled');

      return;
    }

    console.log('[TRACKING] Enabling tracking');

    this.firebase = new ODFirebase(this.config);
    this.analytics = new Analytics(this.firebase);
    this.remoteConfig = new RemoteConfig(this.firebase);

    GTMHelper.clearDataLayer();
    this.analytics.setUserId(this.userId);
    await this.loadRemoteConfig();

    this.isEnabled = true;
    this.updateDataLayer();

    if (window.clarity) {
      window.clarity('start');
      window.clarity('consent');
    }
  }


  public async disable() {
    // Wait for the bank to initialize so that multiple things aren't trying to write to it
    await this.bankStart;

    Tracking.setBankedValue(false);

    if (!this.isEnabled) {
      console.log('[TRACKING] Tracking already disabled');

      return;
    }

    console.log('[TRACKING] Disabling tracking');

    this.isEnabled = false;

    this.firebase?.release();

    this.analytics = undefined;
    this.remoteConfig = undefined;
    this.firebase = undefined;

    GTMHelper.clearDataLayer();

    if (window.clarity) {
      window.clarity('stop');
      window.clarity('consent', false);
    }
  }


  public log<T extends AnalyticsEventName>(eventName: T, ...eventParams: (AnalyticsEventParams<T> extends undefined ? [] : [AnalyticsEventParams<T>])) {
    if (!this.isEnabled || !this.analytics) {
      console.log('[TRACKING] Tracking currently disabled. Event not logged.', { name: eventName, params: eventParams[0] });

      return;
    }

    console.log('[TRACKING] Tracking event logged', { name: eventName, params: eventParams[0] });
    this.analytics.log(eventName, eventParams[0]);
  }


  private async initializeBank() {
    const allowed = await this.checkTrackingAllowed();
    Tracking.setBankedValue(allowed);

    return allowed;
  }


  private async checkTrackingAllowed() {
    // check do not track browser setting
    if (navigator.doNotTrack === '1') {
      return false;
    }

    // check for bank setting
    const storedSetting = Tracking.getBankedValue();

    if (storedSetting !== undefined) {
      console.log(`[TRACKING] Stored tracking setting found. Stored value: ${storedSetting}`);

      return storedSetting;
    }

    // check ip
    try {
      const status = await APP.services.geoIp.getGdprStatus();
      console.log('[TRACKING] Geo IP status', status);

      if (!!status && !status.isGdpr && !APP.flags.get('gdpr')) {
        return true;
      }

      return undefined;
    } catch {
      console.log('[TRACKING] Error contacting Geo IP API');

      return undefined;
    }
  }


  private updateDataLayer(updates: Partial<ElrondGTMDataLayer> = {}) {
    this.dataLayer = { ...this.dataLayer, ...updates };

    if (this.isEnabled) {
      console.log('[TRACKING] Sending data layer update to window', this.dataLayer);
      GTMHelper.setDataLayer(this.dataLayer);
    }
  }


  private async loadRemoteConfig() {
    if (!this.remoteConfig) {
      console.log('[TRACKING] Tracking disabled. Cannot load remote config.');

      return;
    }

    if (!this.libraryId) {
      console.log('[TRACKING] Library not yet loaded. Cannot load remote config.');

      return;
    }

    try {
      console.log('[TRACKING] Loading library config');
      const storedConfig = await this.remoteConfig.getValue('CONFIG_' + this.libraryId);
      if (storedConfig) {
        const libraryConfig = JSON.parse(storedConfig);
        console.log('[TRACKING] Loaded stored library config', libraryConfig);

        this.updateDataLayer(libraryConfig);
      }
    } catch {
      console.warn(`[TRACKING] Could not parse config for library ${this.libraryId}`);
    }
  }


  private registerGlobalEvents() {
    Events.on('patron:accountId:acquired', async () => {
      const setting = await this.bankStart;

      if (setting) {
        this.enable();
      }
    });

    Events.on('auth:logout', async () => {
      await this.disable();

      Tracking.setBankedValue(undefined);
    });

    // Analytics events
    Events.on('environment:ready', this.onAppReady.bind(this));
    Events.on('router:navigate', this.onNavigate.bind(this));
    Events.on('auth:complete', this.onAuthComplete.bind(this));
    Events.on('auth:logout', this.onLogout.bind(this));
    Events.on('title:open', this.onTitleOpen.bind(this));
    Events.on('app:error', this.onAppError.bind(this));
    Events.on('msg:track:annotation:activity', this.onAnnotationActivity.bind(this));

    // GTM events
    Events.on('platform:info', this.onPlatformInfo.bind(this));
    Events.on('library:load', this.onLibraryUpdate.bind(this));
    Events.on('library:update', this.onLibraryUpdate.bind(this));
    Events.on('patron:accountId:acquired', this.onAccountIdAcquired.bind(this));
    Events.on('loan:load:all', this.onLoansUpdate.bind(this));
    Events.on('loan:update:all', this.onLoansUpdate.bind(this));
  }


  private onAppError(evt: DataEvent<{ errorMessage: string; errorSource: string }>): void {
    const context: AppErrorContext = {
      description: evt.m?.errorMessage
    };

    this.log('exception', context);
  }


  private onAppReady(): void {
    this.log('app_ready');
  }


  private onNavigate(evt: DataEvent<RouteLocationNormalized>): void {
    if (this.isEnabled) {
      const {
        hash,
        meta,
        name,
        path,
        query
      } = evt.m;

      GTMHelper.addPageView({
        path,
        query: stringifyQuery(query),
        hash,
        title: meta.title as string || document.title,
        pageName: name as string || ''
      });

      const gaPageTitle = name as string || meta.title as string || '';
      const gaPageLocation = path;

      // If we have a shell and the platform is not the browser 'PWA', treat as an app
      if (APP.shell.info.type === 'Native' && APP.shell.info.platform !== 'PWA') {

        // TODO: manual screen_view events will be enabled later

        // this.log('screen_view', {
        //   firebase_screen: gaPageTitle,
        //   firebase_screen_class: gaPageLocation
        // });
      } else {
        this.log('page_view', {
          page_title: gaPageTitle,
          page_location: gaPageLocation,
          page_query: stringifyQuery(query),
          page_hash: hash
        });
      }
    }
  }


  private onAuthComplete(): void {
    this.log('login');
  }


  private onLogout(): void {
    GTMHelper.clearDataLayer();
  }


  private onTitleOpen(evt: DataEvent<{ slug: string; loan?: Loan; isBundledChild: boolean; parent: string }>): void {
    if (!evt || !evt.m) { return; }

    const titleSlug = (evt.m.parent) ? evt.m.parent : evt.m.slug;
    const context: TitleOpenContext = {
      title_slug: titleSlug,
      title_name: null,
      reserveID: null,
      format: null
    };

    if (evt.m.loan && evt.m.loan.titleRecord) {
      const titleName = evt.m.loan.titleRecord.title;
      const subtitle = evt.m.loan.titleRecord.subtitle;

      context.title_name = subtitle ? `${titleName} ${subtitle}` : titleName;
      context.format = evt.m.loan.titleRecord.mediaType;
      context.reserveID =  evt.m.loan.titleRecord.lexisMetadata?.contentReserveID || null;
    }

    this.log('title_opened', context);
  }


  private onAnnotationActivity(evt: DataEvent<AnalyticsAnnotationActivity>): void {
    const act = evt.m.data;
    const bifocalTitle = APP.shell.bifocal.title();

    this.log(`annotation_${act.action}`, {
      is_set: false,
      title_slug: bifocalTitle.lexisMetadata.parent || bifocalTitle.slug,
      title_name: bifocalTitle.subtitle ? `${bifocalTitle.title} ${bifocalTitle.subtitle}` : bifocalTitle.title,
      highlight_color: i18n.t(`annotations.color.${act.color}`),
      annotation_note: act.note
    });
  }


  private onPlatformInfo(evt: DataEvent<ShellInfo>) {
    this.updateDataLayer({ platform: evt.m.platform });
  }


  private async onLibraryUpdate(evt: DataEvent<Library | undefined>) {
    const library = evt.m;

    this.libraryId = library?.websiteId.toString() || undefined;

    await this.loadRemoteConfig();

    this.analytics?.setUserProperty('libraryId', this.libraryId || '');
    this.analytics?.setUserProperty('libraryName', library?.name || '');

    this.updateDataLayer({ library: evt.m });
  }


  private onAccountIdAcquired(evt: DataEvent<{ accountId: string}>) {
    this.updateDataLayer({ accountId: evt.m.accountId });
  }


  private onLoansUpdate(evt: DataEvent<CollationUpdate<Loan>>) {
    this.updateDataLayer({ session: { checkedOut: evt.m.all.length } });
  }


  public static getBankedValue(): boolean | undefined {
    if (!APP?.bank) { return undefined; }

    const stored = APP.bank.get(BANK_KEY);

    return stored;
  }


  private static setBankedValue(value: boolean | undefined) {
    if (!APP?.bank) { return; }

    if (value === undefined) {
      APP.bank.clear(BANK_KEY);
    } else {
      APP.bank.set(BANK_KEY, value);
    }
  }


  private static readFirebaseConfig(env: BuildEnvironment): FirebaseConfig {
    return {
      apiKey: env.FIREBASE_API_KEY || undefined,
      appId: env.FIREBASE_APP_ID!,
      authDomain: env.FIREBASE_AUTH_DOMAIN!,
      databaseURL: env.FIREBASE_DATABASE_URL!,
      measurementId: env.FIREBASE_MEASUREMENT_ID || undefined,
      messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID!,
      projectId: env.FIREBASE_PROJECT_ID || undefined,
      storageBucket: env.FIREBASE_STORAGE_BUCKET!
    };
  }
}
