// These need to remain in a very specific order for the App to load
import App from 'app/App.vue';

import { plugin as i18nPlugin } from 'app/i18n/i18n';

import router from 'app/router/router';

import 'app/base/a11y/dialog';

import { APP } from 'app/base/app';

import { configureVue } from 'app/configure';

import { Bridge } from 'app/base/shell/bridge';


declare global {
  interface Window {
    APP: unknown;
    NAUTILUS?: { onScriptError: (errorMessage: string, errorSource: string, lineNumber?: number, reportOnly?: boolean) => void };
    BRIDGE?: Bridge;
    grecaptcha?: any;
    clarity: any;
  }
}

window.onload = APP.start.bind(APP);
window.APP = APP;
const rs = document.readyState;
if (rs === 'complete' || rs === 'interactive') {
  window.onload(null);
  window.onload = null;
}

// Add overlay elements to HTML, if they don't exist.
// We use these for Vue's Teleport target, which requires
// the target to exist before Vue renders.
const overlays = document.getElementById('overlays');
if (!document.getElementById('toasts')) {
  const toasts = document.createElement('div');
  toasts.id = 'toasts';
  overlays!.appendChild(toasts);
}

if (!document.getElementById('modals')) {
  const modals = document.createElement('div');
  modals.id = 'modals';
  overlays!.appendChild(modals);
}

document.documentElement.addEventListener('environment:ready', () => {
  console.log('[VUE] APP is done initializing, starting Vue...');

  const app = configureVue((h) => h(App), router, i18nPlugin);

  app.mount('#app');
});
