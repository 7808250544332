import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBarMobile = _resolveComponent("SearchBarMobile")!
  const _component_SearchBarDesktop = _resolveComponent("SearchBarDesktop")!

  return (_ctx.mobile)
    ? (_openBlock(), _createBlock(_component_SearchBarMobile, {
        key: 0,
        query: _ctx.query,
        "onUpdate:query": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        groups: _ctx.suggestionGroups,
        onSubmit: _ctx.submit
      }, null, 8, ["query", "groups", "onSubmit"]))
    : (_openBlock(), _createBlock(_component_SearchBarDesktop, {
        key: 1,
        query: _ctx.query,
        "onUpdate:query": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
        groups: _ctx.suggestionGroups,
        onSubmit: _ctx.submit
      }, null, 8, ["query", "groups", "onSubmit"]))
}