import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["placeholder", "aria-label"]
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = ["id", "onClick"]
const _hoisted_6 = ["id", "aria-label", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("dialog", {
    ref: "dialog",
    class: _normalizeClass(_ctx.$style.dialog),
    "aria-label": _ctx.$t('search.mobileHeading'),
    onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)), ["prevent"]), ["esc"]))
  }, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "notch" }, null, -1)),
    _createElementVNode("form", {
      class: _normalizeClass(_ctx.$style.searchBar),
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => _ctx.triggerAction(), ["prevent"]))
    }, [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(_ctx.$style.closeButton),
        "aria-label": _ctx.$t('search.closeButton'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_Icon, { name: "chevron-left" })
      ], 10, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.searchBoxContainer)
      }, [
        _withDirectives(_createElementVNode("input", {
          ref: "searchBox",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputQuery) = $event)),
          class: _normalizeClass(_ctx.$style.searchBox),
          type: "text",
          placeholder: _ctx.$t('search.placeholder'),
          "aria-label": _ctx.$t('search.placeholder'),
          onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args)), ["prevent","stop"]), ["escape"]))
        }, null, 42, _hoisted_3), [
          [_vModelText, _ctx.inputQuery]
        ])
      ], 2),
      (_ctx.inputQuery)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: _normalizeClass(_ctx.$style.clearButton),
            "aria-label": _ctx.$t('search.clearButton'),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args)))
          }, [
            _createVNode(_component_Icon, {
              name: "dismiss",
              class: _normalizeClass(_ctx.$style.clearIcon)
            }, null, 8, ["class"])
          ], 10, _hoisted_4))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass(_ctx.$style.searchButton)
      }, _toDisplayString(_ctx.$t('search.searchButton')), 3)
    ], 34),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.suggestions)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: group.id
        }, [
          _createElementVNode("h2", {
            class: _normalizeClass(_ctx.$style.suggestionGroupLabel)
          }, _toDisplayString(group.label), 3),
          _createElementVNode("ul", {
            class: _normalizeClass(_ctx.$style.suggestionGroup)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.suggestions, (suggestion) => {
              return (_openBlock(), _createElementBlock("li", {
                key: suggestion.id,
                class: _normalizeClass(_ctx.$style.suggestion)
              }, [
                _createVNode(_component_Icon, {
                  class: _normalizeClass([_ctx.$style[group.iconName], _ctx.$style.suggestionIcon]),
                  name: group.iconName
                }, null, 8, ["class", "name"]),
                _createElementVNode("button", {
                  id: suggestion.select.id,
                  class: _normalizeClass(_ctx.$style.suggestionText),
                  onClick: () => _ctx.triggerAction(suggestion.select)
                }, _toDisplayString(suggestion.text), 11, _hoisted_5),
                (suggestion.delete)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      id: suggestion.delete.id,
                      class: _normalizeClass(_ctx.$style.suggestionDeleteButton),
                      "aria-label": _ctx.$t('search.deleteButton', { query: suggestion.text }),
                      onClick: () => _ctx.triggerAction(suggestion.delete)
                    }, [
                      _createVNode(_component_Icon, {
                        class: _normalizeClass(_ctx.$style.trashcan),
                        name: "trashcan"
                      }, null, 8, ["class"])
                    ], 10, _hoisted_6))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ], 2)
        ], 64))
      }), 128))
    ], 2)
  ], 42, _hoisted_1))
}