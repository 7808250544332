import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileSearchTrigger = _resolveComponent("MobileSearchTrigger")!
  const _component_MobileSearchDialog = _resolveComponent("MobileSearchDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MobileSearchTrigger, {
      query: _ctx.query,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSearch = true))
    }, null, 8, ["query"]),
    (_ctx.showSearch)
      ? (_openBlock(), _createBlock(_component_MobileSearchDialog, {
          key: 0,
          query: _ctx.inputQuery,
          "onUpdate:query": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputQuery) = $event)),
          groups: _ctx.groups,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSearch = false)),
          onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('submit')))
        }, null, 8, ["query", "groups"]))
      : _createCommentVNode("", true)
  ], 64))
}