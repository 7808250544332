<template>
  <MobileSearchTrigger
    :query="query"
    @click="showSearch = true"
  />
  <MobileSearchDialog
    v-if="showSearch"
    v-model:query="inputQuery"
    :groups="groups"
    @close="showSearch = false"
    @submit="$emit('submit')"
  />
</template>

<script lang="ts">
import MobileSearchDialog from 'app/components/MobileSearchDialog.vue';
import MobileSearchTrigger from 'app/components/MobileSearchTrigger.vue';
import { SearchGroup } from 'app/components/SearchBar.vue';
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  components: {
    MobileSearchDialog,
    MobileSearchTrigger
  },
  props: {
    query: {
      type: String,
      required: true
    },
    groups: {
      type: Array as PropType<SearchGroup[]>,
      required: true
    }
  },
  emits: [
    'submit',
    'update:query'
  ],
  setup: (props, { emit }) => {
    const showSearch = ref<boolean>(false);

    const inputQuery = computed({
      get: () => props.query,
      set: (val: string) => emit('update:query', val)
    });

    return {
      inputQuery,
      showSearch
    };
  }
});
</script>

<style module>

</style>
